import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import TermsAndConditionsContainer from '../containers/TermsContainer'

const TermsAndConditions = (props) => {
  return (
    <Layout
      pageInfo={{ pageName: "About Us" }}
      styleClass="home-page"
      mainClass="main-about"
      location={props.location.pathname}>
      <SEO title="Terms and Conditions" />
      <TermsAndConditionsContainer />
    </Layout>
  )
}

export default TermsAndConditions
