import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/blogs/banner.png'
import TermsContent from '../components/privacy/TermsContent'

const TermsContainer = (props) => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <BannerImage
              noText={true}
              activeTab="Terms & Conditions"
              bannerImage={banner} />
          </Col>
        </Row>
      </Container>
      <Container className='mt-4'>
        <Row>
          <Col>
            <TermsContent />
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default TermsContainer
